import React from 'react';
import { Link } from 'gatsby';
import { styled } from '@mui/material';

export type BreadcrumbsProps = { children: React.ReactNode[] };
export type BreadcrumbItemProps = { to: string; label: string };

export type BreadcrumbsComposition = React.FC<BreadcrumbsProps> & {
  Item: React.FC<BreadcrumbItemProps>;
};

const CustomBreadcrumbs = styled('div')`
  font-family: 'HCo Gotham SSm';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.greyscale['05']};
`;

const CustomBreadcrumbLink = styled('span')`
  a {
    color: ${({ theme }) => theme.palette.greyscale['05']};
  }
  &:not(:last-child) {
    a {
      color: #45c7ff;
    }
  }
`;

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ to, label }) => (
  <Link to={to}>{label}</Link>
);

const Breadcrumbs: BreadcrumbsComposition = ({ children }) => {
  const links = React.Children.map(children, (item, index) => (
    <CustomBreadcrumbLink>
      {item}
      {index < children.length - 1 ? ' / ' : null}
    </CustomBreadcrumbLink>
  ));
  return <CustomBreadcrumbs>{links}</CustomBreadcrumbs>;
};

Breadcrumbs.Item = BreadcrumbItem;

export default Breadcrumbs;
