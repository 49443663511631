import { styled, Typography, TypographyProps } from '@mui/material';
import React from 'react';

import theme from '../theme';
import { Icon } from './icon';

const Container = styled('div')(() => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.greyscale.white,
}));

const Title = styled('div')`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0rem;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${theme.palette.greyscale.white};
  cursor: pointer;
`;

const CustomH4 = styled('h2')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 16px 0;
`;

const BodyME = styled('h4')`
  fontFamily: 'HCo Gotham SSm',
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '28px',
  margin: 16px 0;
`;

const Content = styled('div')(() => ({
  fontFamily: 'HCo Gotham SSm',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.01em',
  margin: '12px 0 12px 0',
  '& p': {
    textTransform: 'none',
  },
  '& ul>li': {
    textTransform: 'none',
  },
}));

export type CollapsibleItemParagraphProps = TypographyProps & { text?: string };
export type CollapsibleItemProps = {
  title: string;
  expanded?: boolean;
  altTitleStyle?: boolean;
};
export type CollapsibleItemCompProps = React.FC<CollapsibleItemProps> & {
  Paragraph: React.FC<CollapsibleItemParagraphProps>;
  List: React.HTMLFactory<any>;
};

const CollapsibleItemList = styled('ul')(() => ({
  paddingTop: theme.spacing(0.5),
  '& li': {
    fontFamily: 'HCo Gotham SSm',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
    fontSize: '14px',
    textTransform: 'none',
    color: theme.palette.greyscale.white,
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: { fontSize: '12px' },
  },
}));

const CollapsibleItemParagraph: React.FC<CollapsibleItemParagraphProps> = ({
  children,
  text,
  ...props
}) => (
  <Typography
    {...props}
    variant="body2"
    sx={{
      marginTop: 1.5,
      marginBottom: 1.5,
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: { fontSize: '12px' },
    }}
  >
    {text ?? children}
  </Typography>
);

const CollapsibleItem: CollapsibleItemCompProps = ({
  title,
  altTitleStyle,
  children,
  expanded,
}) => {
  const [open, setOpen] = React.useState(expanded ?? false);
  const handleVisibilityToggle = () => setOpen(!open);

  return (
    <Container>
      <Title
        onClick={handleVisibilityToggle}
        role="button"
        aria-expanded={open}
      >
        {altTitleStyle ? (
          <BodyME>{title}</BodyME>
        ) : (
          <CustomH4>{title}</CustomH4>
        )}
        {children && (
          <Icon
            color={theme.palette.greyscale['03']}
            iconName={open ? 'arrow-up' : 'arrow-down'}
          />
        )}
      </Title>
      {open && <Content>{children}</Content>}
    </Container>
  );
};

CollapsibleItem.Paragraph = CollapsibleItemParagraph;
CollapsibleItem.List = CollapsibleItemList;

export default CollapsibleItem;
