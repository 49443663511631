import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Layout from '../components/layout';
import { PageHeader, NavInView } from '../components/header';
import SEO from '../components/seo';
import { ElevationScroll } from '../components/header/utils';
import CollapsibleItem from '../components/collapsible-item';
import Breadcrumbs from '../components/breadcrumbs';
import { IFaq } from '../components/GlobalState';

export enum SectionTypes {
  COLLAPSE = 'SectionCollapsible05',
  GENERAL = 'SectionGeneral05',
  CALLOUT = 'SectionCallout05',
  FULL_SIZE = 'SectionFullSize05',
  HERO = 'SectionHero05',
  IMAGE_CARD = 'SectionImageCard05',
  LOTTIE = 'SectionLottie05',
  POP_SWAP = 'SectionPopSwap05',
  SLIDE_SHOW = 'SectionSlideshow05',
  BREAKOUT_HEADING = 'SectionBreakoutHeading05',
}

export type PageProps = {
  pageContext: {
    page: {
      slug: string;
      title: string;
      description: string;
      navbar?: {
        links: {
          name: string;
          url: string;
        }[];
      };
      content: {
        raw: any;
        references: any;
      };
    };
    faqs?: IFaq;
  };
};

const Content = styled('div')(({ theme }) => ({
  margin: '0 auto',
  padding: theme.spacing(1),

  [theme.breakpoints.up('xs')]: {
    maxWidth: `${theme.breakpoints.values.sm / 2}px`,
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: `${theme.breakpoints.values.sm}px`,
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: `${theme.breakpoints.values.md}px`,
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: `${theme.breakpoints.values.lg}px`,
    padding: theme.spacing(4),
  },

  '& .MuiTypography-root': {
    color: theme.palette.greyscale.white,
  },
  '& .MuiTypography-body1': {
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  '& .MuiTypography-h1': {
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textTransform: 'uppercase',

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '36px',
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '36px',
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      lineHeight: '40px',
      marginTop: theme.spacing(4),
    },
  },
}));

const StyledUl = styled('ul')(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  li: {
    fontFamily: 'HCo Gotham SSm',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    paddingTop: theme.spacing(1.5),
  },
}));

const convertToSlug = (title: string) =>
  title.toLocaleLowerCase().split(' ').join('-');

const Page: React.FC<PageProps> = ({ pageContext: { page } }) => (
  <Layout>
    <SEO title={page.title} description={page.description} />
    {page.navbar?.links && (
      <ElevationScroll>
        <PageHeader links={page.navbar.links} />
      </ElevationScroll>
    )}
    <Content>
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumbs.Item to="/" label="Home" />
        <Breadcrumbs.Item to={`/${page.slug}`} label={page.title} />
      </Breadcrumbs>
      <Typography variant="h1">{page.title}</Typography>
      <NavInView id={convertToSlug(page.title)}>
        {page.content?.raw && (
          <RichText
            content={page.content?.raw}
            references={page.content?.references}
            renderers={{
              p: ({ children }) => (
                <Typography variant="body1">{children}</Typography>
              ),
              ul: ({ children }) => <StyledUl>{children}</StyledUl>,
              embed: {
                Section05: ({ anchorLink, sectionTypes }) => (
                  <div id={anchorLink}>
                    {sectionTypes?.map((sectionType: any) => {
                      if (sectionType?.id.includes(SectionTypes.COLLAPSE)) {
                        return (
                          <CollapsibleItem
                            title={sectionType?.title}
                            key={sectionType?.id}
                          >
                            <RichText
                              content={sectionType?.content?.raw}
                              renderers={{
                                p: ({ children }) => (
                                  <CollapsibleItem.Paragraph>
                                    {children}
                                  </CollapsibleItem.Paragraph>
                                ),
                                ul: ({ children }) => (
                                  <CollapsibleItem.List>
                                    {children}
                                  </CollapsibleItem.List>
                                ),
                              }}
                            />
                          </CollapsibleItem>
                        );
                      }
                      return null;
                    })}
                  </div>
                ),
              },
            }}
          />
        )}
      </NavInView>
    </Content>
  </Layout>
);

export default Page;
